import React from 'react';

import {
  getFacebookShareLink,
  getTwitterShareLink,
  getCopyLink
} from '../../lib/get-share-links';

import CopyIcon from '../icons/copy';
import FacebookIcon from '../icons/facebook';
import TwitterIcon from '../icons/twitter';

import './share.css';

/**
 * Helper function that opens link as popup
 */
const openAsPopup = (name, url) => event => {
  event.preventDefault();
  const popup = window.open(
    url,
    `share_via_${name}`,
    'location=no,menubar=no,scrollbars=no,status=no,toolbar=no,width=640,height=480'
  );
  popup.opener = null;
};

/**
 * Share link component
 */
const ShareLink = props => {
  const {asPopup, name, href} = props;

  const popupProps = asPopup ? {onClick: openAsPopup(name, href)} : {};

  return (
    <a
      {...popupProps}
      href={href}
      target="_blank"
      rel="noopener nofollow noreferrer">
      {props.children}
    </a>
  );
};

/**
 * Helper that copies string to clipboard
 */
function copyToClipboard(string) {
  const el = document.createElement('textarea');
  el.value = string;
  el.setAttribute('readonly', '');
  el.style.position = 'absolute';
  el.style.left = '-9999px';
  document.body.appendChild(el);
  const selected =
    document.getSelection().rangeCount > 0
      ? document.getSelection().getRangeAt(0)
      : false;
  el.select();
  document.execCommand('copy');
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
}

/**
 * The share component
 */
export default class ShareButton extends React.Component {
  /**
   * Handles a click on the "copy" share button
   */
  handleCopyClick = () => {
    const {customMessage, signature, onCopyLinkClick} = this.props;

    const content = {message: customMessage, signature};
    const copyLink = getCopyLink(content);

    copyToClipboard(copyLink);
    onCopyLinkClick();
  };

  render() {
    const {customMessage, signature} = this.props;
    const content = {message: customMessage, signature};

    const facebookLink = getFacebookShareLink(content);
    const twitterLink = getTwitterShareLink(content);

    return (
      <footer className="share-container">
        <nav className="share-buttons">
          <span className="share-hint">Share via</span>
          <div className="share-button">
            <ShareLink
              className="share-button"
              name="facebook"
              href={facebookLink}
              asPopup>
              <FacebookIcon />
            </ShareLink>
          </div>
          <div className="share-button">
            <ShareLink
              className="share-button"
              name="twitter"
              href={twitterLink}
              asPopup>
              <TwitterIcon />
            </ShareLink>
          </div>
          <div className="share-button">
            <button className="share-button" onClick={this.handleCopyClick}>
              <CopyIcon />
            </button>
          </div>
        </nav>
      </footer>
    );
  }
}
