import React from 'react';

import MessageIcon from '../icons/message';

import './greeting.css';

/**
 * The ubilabs greeting message
 */
export default function CreateGreeting(props) {
  return (
    <button className="create-greeting-button" onClick={props.showCustomInput}>
      <MessageIcon />
      <span>Share your own</span>
    </button>
  );
}
