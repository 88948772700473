import queryString from 'query-string';

function decode(string) {
  return decodeURIComponent(atob(string));
}

/**
 * Returns the decoded message and signature from the URL
 */
export function getGreetingFromUrl() {
  const {msg, sndr} = queryString.parse(window.location.search);
  if (!msg) {
    return null;
  }

  try {
    const decodedMessage = decode(msg);
    const decodedSignature = decode(sndr);

    return {
      message: decodedMessage,
      signature: decodedSignature
    };
  } catch (e) {
    return null;
  }
}
