import isSmallScreen from './is-small-screen';

const mobileFontSizeMap = {
  small: 36,
  big: 48
};

const desktopFontSizeMap = {
  small: 54,
  big: 58
};

const DEFAULT_FONTSIZE = 58;

/**
 * Helper to scale font size acoording to message length
 */
export default function scaleFontSize(message) {
  let fontSize = DEFAULT_FONTSIZE;

  const fontSizeMap = isSmallScreen() ? mobileFontSizeMap : desktopFontSizeMap;

  if (!message) {
    return {
      fontSize: `${fontSize}px`
    };
  }

  if (message.length > 40) {
    fontSize = fontSizeMap['small'];
  } else if (message.length > 20) {
    fontSize = fontSizeMap['big'];
  }

  return {
    fontSize: `${fontSize}px`,
    lineHeight: `${fontSize}px`
  };
}
