import React from 'react';
import Textarea from 'react-textarea-autosize';

import getTextAreaRowLimit from '../../lib/get-textarea-row-limit';

import './share.css';

const MAX_CHAR_LENGTH = 80;

/**
 * Scrolls textarea into view to mitigate safari mobile effect where focused
 * inputs gets centered
 */
function centerOnTextArea(event) {
  const textarea = event.target;
  document.body.scrollTop = textarea.scrollHeight;
}

/**
 * Form where user can enter own custom message
 */
class CustomMessage extends React.Component {
  /**
   * The initial state
   */
  state = {
    maxRows: 4
  };

  componentDidMount() {
    const maxRows = getTextAreaRowLimit();
    this.setState({maxRows});

    window.addEventListener('resize', this.resizeTextArea);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resizeTextArea);
  }

  /**
   * Event handler for that ensures max length on mobile
   */
  handleMessageChange = (event, component) => {
    const charCount = event.target.value.length;
    const {onMessageChange} = this.props;
    if (charCount > MAX_CHAR_LENGTH) {
      event.target.value = event.target.value.slice(0, MAX_CHAR_LENGTH);
    }
    onMessageChange(event);
  };

  /**
   * Updates row limit on resize
   */
  resizeTextArea = () => {
    const maxRows = getTextAreaRowLimit();
    this.setState({maxRows});
  };

  render() {
    const {customMessage, signature, onSignatureChange} = this.props;
    const {maxRows} = this.state;

    return (
      <form
        className="custom-message-form"
        onSubmit={event => event.preventDefault()}>
        <div className="custom-message__input-container">
          <Textarea
            className="custom-message__input"
            maxRows={maxRows}
            maxLength={MAX_CHAR_LENGTH}
            onChange={this.handleMessageChange}
            onFocus={centerOnTextArea}
            value={customMessage}
            placeholder="Type your message"
            autoFocus
          />
          <span className="character-counter">
            {`${customMessage.length}/${MAX_CHAR_LENGTH}`}
          </span>
        </div>
        <div>
          <span className="signature">from</span>
          <input
            className="signature__input"
            onChange={onSignatureChange}
            value={signature}
            type="text"
          />
        </div>
      </form>
    );
  }
}

export default CustomMessage;
