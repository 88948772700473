import React from 'react';

import {getGreetingFromUrl} from '../../lib/get-greeting-from-url';
import getFontSize from '../../lib/get-font-size';

import './greeting.css';

/**
 * The ubilabs or custom greeting message
 */
export default function UbilabsGreeting(props) {
  const greeting = getGreetingFromUrl();

  if (!greeting) {
    return (
      <article className="greeting">
        <h1 className="message--default">
          We wish you a joyous Christmas and
          <span style={{display: 'block'}}>a most prosperous New Year!</span>
        </h1>
        <h2 className="message">the Ubilabs family</h2>
      </article>
    );
  }
  const {message, signature} = greeting;

  return (
    <article className="greeting">
      <p className="message" style={getFontSize(message)}>
        {message}
      </p>
      {Boolean(signature) && <h2 className="message">from {signature}</h2>}
    </article>
  );
}
