import React from 'react';

import CancelIcon from '../icons/cancel';
import CustomMessage from './custom-message';
import ShareButtons from './share-buttons';

import './share.css';

/**
 * Form where user can enter own custom message
 */
function CustomMessageDialog(props) {
  const {
    cancelCustomMessage,
    customMessage,
    signature,
    onCopyLinkClick,
    onMessageChange,
    onSignatureChange
  } = props;

  return (
    <div className="custom-message-dialog">
      <div className="custom-message-container">
        <button className="custom-message-cancel" onClick={cancelCustomMessage}>
          <CancelIcon />
        </button>
        <CustomMessage
          customMessage={customMessage}
          signature={signature}
          onMessageChange={onMessageChange}
          onSignatureChange={onSignatureChange}
          cancelCustomMessage={cancelCustomMessage}
        />
        <ShareButtons
          customMessage={customMessage}
          signature={signature}
          onCopyLinkClick={onCopyLinkClick}
        />
      </div>
    </div>
  );
}

export default CustomMessageDialog;
