const BASE_URL = 'xmas2018.ubilabs.net';

function encode(string) {
  return btoa(encodeURIComponent(string));
}

/**
 * Helper that generates the encoded share url
 */
function getUrl(content) {
  const {message = '', signature = ''} = content;
  const encodedMessage = encode(message);
  const encodedSignature = encode(signature);

  const url = Boolean(message)
    ? `http://${BASE_URL}/?msg=${encodedMessage}&sndr=${encodedSignature}`
    : `https://${BASE_URL}`;

  return encodeURIComponent(url);
}

/**
 * Generates a link to share on Facebook
 */
export function getFacebookShareLink(content) {
  const url = getUrl(content);
  const quote =
    'May the Season bring only happiness and joy to you and your loved ones!';

  return `https://www.facebook.com/sharer/sharer.php?t=${quote}&u=${url}`;
}

/**
 * Generates a link to share on Twitter
 */
export function getTwitterShareLink(content) {
  const url = getUrl(content);
  const text =
    'Have fun with this little Christmas gimmick, designed by the peeps over at @ubilabs. May the Season bring only happiness and joy to you and your loved ones!';

  return `https://twitter.com/intent/tweet?url=${url}&text=${text}`;
}

/**
 * Generates a link to copy to the clipboard
 */
export function getCopyLink(content) {
  const {message = '', signature = ''} = content;
  const encodedMessage = encode(message);
  const encodedSignature = encode(signature);

  const url = Boolean(message)
    ? `http://${BASE_URL}/?msg=${encodedMessage}&sndr=${encodedSignature}`
    : `https://${BASE_URL}`;

  return url;
}
