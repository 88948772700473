import React from 'react';
import {SizeMe} from 'react-sizeme';

import CustomMessageDialog from './custom-message/custom-message-dialog';
import GreetingDialog from './greeting-card/greeting-dialog';

import ThreeJsWrapper from './scene/ThreeJsWrapper';

import {
  isInternetExplorer11,
  doesntSupportWebGl
} from '../lib/detect-unsupported-envs';

export default class App extends React.Component {
  /**
   * The initial state
   */
  state = {
    isGreetingShown: true,
    isShareFormShown: false,
    customMessage: '',
    signature: '',
    isTooltipShown: false,
    isUnsupportedEnv: false
  };

  componentDidMount() {
    const isUnsupportedEnv = isInternetExplorer11() || doesntSupportWebGl();
    this.setState({isUnsupportedEnv});
  }

  /**
   * Shows form to write own custom message
   */
  showCustomInput = () => {
    this.setState({isGreetingShown: false, isShareFormShown: true});
  };

  /**
   * Cancels custom message dialog
   */
  cancelCustomMessage = () => {
    this.setState({
      isGreetingShown: true,
      isShareFormShown: false,
      customMessage: '',
      signature: ''
    });
  };

  /**
   * Handles changes of the custom message
   */
  onMessageChange = event => {
    const customMessage = event.target.value;
    this.setState({customMessage});
  };

  /**
   * Handles changes of the custom message signature
   */
  onSignatureChange = event => {
    const signature = event.target.value;
    this.setState({signature});
  };

  /**
   * Shows tooltip on copy link click
   */
  onCopyLinkClick = () => {
    this.setState({isTooltipShown: true});

    setTimeout(() => {
      this.setState({isTooltipShown: false});
    }, 1000);
  };

  render() {
    const {
      customMessage,
      isGreetingShown,
      isShareFormShown,
      signature,
      isTooltipShown,
      isUnsupportedEnv
    } = this.state;

    return (
      <div className="App">
        {isUnsupportedEnv ? (
          <div className="scene--fallback" />
        ) : (
          <SizeMe monitorHeight={true}>
            {({size}) => (
              <div className="scene">
                <ThreeJsWrapper width={size.width} height={size.height} />
              </div>
            )}
          </SizeMe>
        )}

        {isUnsupportedEnv && (
          <h2 className="unsupported-message">
            Your browser environment is not supported, nevertheless:
          </h2>
        )}

        {isGreetingShown && (
          <GreetingDialog showCustomInput={this.showCustomInput} />
        )}

        {isShareFormShown && (
          <CustomMessageDialog
            customMessage={customMessage}
            signature={signature}
            onCopyLinkClick={this.onCopyLinkClick}
            onMessageChange={this.onMessageChange}
            onSignatureChange={this.onSignatureChange}
            cancelCustomMessage={this.cancelCustomMessage}
          />
        )}

        {isTooltipShown && (
          <div className="tooltip">
            <p>Link copied to clipboard</p>
          </div>
        )}
      </div>
    );
  }
}
