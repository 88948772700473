/**
 * Helper function to choose optimal row limit for text area depending on size
 * of device screen
 */
export default function getTextareaRowLimit() {
  const innerHeight = window.innerHeight;
  switch (true) {
    case innerHeight >= 800:
      return 6;
    case innerHeight >= 400:
      return 4;
    default:
      return 2;
  }
}
