import React from 'react';

import UbiLogo from '../icons/ubi-logo';
import UbiGreeting from './ubi-greeting';
import CreateGreetingButton from './create-greeting';

import './greeting.css';

/**
 * The greeting card
 */
function GreetingCardDialog(props) {
  const {showCustomInput} = props;

  return (
    <div className="greating-card-dialog">
      <a className="logo" href="https://ubilabs.net/en/news/merry-christmas-2018">
        <UbiLogo />
      </a>
      <UbiGreeting />
      <CreateGreetingButton showCustomInput={showCustomInput} />
    </div>
  );
}

export default GreetingCardDialog;
