/**
 * Checks if viewed in internet explorer 11
 */
export function isInternetExplorer11() {
  return !!window.MSInputMethodContext && !!document.documentMode;
}

/**
 * Check whether env supports webGl
 */
export function doesntSupportWebGl() {
  const canvas = document.createElement('canvas');
  const webGlContext =
    canvas.getContext('webgl') || canvas.getContext('experimental-webgl');

  const doesSupportWebGl =
    webGlContext && webGlContext instanceof WebGLRenderingContext;

  return !doesSupportWebGl;
}
