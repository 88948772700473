import React from 'react';

export default function EmailIcon() {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24">
      {/* <path d="M12 12.713l-11.985-9.713h23.97l-11.985 9.713zm0 2.574l-12-9.725v15.438h24v-15.438l-12 9.725z" /> */}
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M16 1H4c-1.1 0-2 .9-2 2v14h2V3h12V1zm-1 4l6 6v10c0 1.1-.9 2-2 2H7.99C6.89 23 6 22.1 6 21l.01-14c0-1.1.89-2 1.99-2h7zm-1 7h5.5L14 6.5V12z" />
    </svg>
  );
}
